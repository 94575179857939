<template>
	<div>Logging out...</div>
</template>
<script>
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { computed, onMounted } from 'vue'
import { useToast } from 'vue-toastification'

export default {
	layout: 'full',
	setup() {
		const store = useStore()
		const router = useRouter()
		const toast = useToast()

		const loggedIn = computed(() => store.state.user.loaded)
		if (store.state.user.loaded) {
			store
				.dispatch('user/logout')
				.then(
					() => {
						toast.success('You have been logged out.')
					},
					err => {
						console.error('Alternate Error handler for logout failure: ', err)
					}
				)
				.catch(err => {
					console.error('Logout Error: ', err)
				})
				.finally(() => {
					router.push('login')
				})
		} else {
			// Not logged in, forwarding to login page
			router.push('login')
		}
		return {
			store,
			router,
		}
	},
}
</script>
